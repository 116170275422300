<template>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink">
        <rect width="24" height="24" fill="url(#pattern002)" />
        <defs>
            <pattern id="pattern002" patternContentUnits="objectBoundingBox" width="1" height="1">
                <use xlink:href="#image0_4131_3817" transform="scale(0.02)" />
            </pattern>
            <image id="image0_4131_3817" width="50" height="50"
                xlink:href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAABmJLR0QA/wD/AP+gvaeTAAACbElEQVRoge2YvWtUQRTFf9GYDWiyIhYmldjZK2gl2AlKYiqL/APmAxt77eLWWhiwtRAr/RfExISY2Gi0sNC4ikIsNKKIshZznxk2b77em/d2izlwm5l7z5mz8+ZrISEhISEhISEhIcEDh4C7wDbQqSG+AovAwdhGFmsy0B13YhvJZuJUbGIDzojel9jE2S9UJ6Jr7otJ1kskI/2GXhv5hHt3+ww8AE66yHq52ENiB7jkQ1onMs1pj9wTwCPJ/wmcdZHWiVDN/cA9qXkHHIlBGgMmzSGgBXzE/pk9BgZ8SauESbOF3YAe131Jq4RJsy3tFy21F4C/wG+61ks/GfEdy4LkvQeOhhbHRFkjg8ATyX0YWhwTZY0AHAd+Sf650OJYiGEEdrfk+0WKY8CkuSXtVzx5zkv+WxtplTBp3tT6QuKPjbRKmDQHUWa2CDQzoBHuOSkrREzNDvT+Gh8NyUi/wdfIMHANWAV+SKwA80Aj4ni6dXaAZWAOdTO2wrVrjQMvMO8YG8BY4IDzNF0665Jj5LIZaWjkL4EJYERiEtjURJy/mMWIr87zHB0vI/PS9wpo5vQ3NZGZEkZCdGZNXNlrLO/9vCR9E5ZBXZacp87hK5zWxLM/E0J0lrW2aWlrg9+rbMQiMOpR7xs+Ot9y6hZAfZ8tdl9noQLNwMFuA7eBG8CHAjq6kTZwC4/1mU35pCVnirBPq6zOUhGBOSneJH8RHgbeSM7VIgIFdEI2lf8YQp0THeA1asGNSkxp5HnbYhU6a8CBoiLjmojpoDpWlDxAZ43wg3cPGqjpf4a6NmRXh1nKzYRL5ztq7c3gmIl/4OSkXUwIGpQAAAAASUVORK5CYII=" />
        </defs>
    </svg>
</template>

<script>
export default {
    name: 'DespIcon',
}
</script>

<style scoped>
</style>