<template>
    <div>
        <hr class="mt-0 mb-1">
        <b-row v-if="!date" align-h="end">
            <b-button variant="primary" class="mr-2" @click="$emit('action')" v-b-tooltip.hover.top="tooltip">
                <feather-icon v-if="mustIcon" :icon="icon" size="16" />
                <span class="mr-25 align-middle">{{ name }}</span>
            </b-button>
        </b-row>

        <b-row v-else>
            <b-col md="4" class="d-flex align-items-center">{{
                $moment(date).format('[Creada en] MMMM DD YYYY') }}</b-col>
            <b-col md="4" offset-md="4"> <b-row align-h="end" class="d-flex align-items-center">
                    <b-button variant="primary" class="mr-2" v-b-tooltip.hover.top="tooltip" @click="$emit('action')">
                        <feather-icon v-if="mustIcon" :icon="icon" size="16" />
                        <span class="mr-25 align-middle">{{ name }}</span>
                    </b-button>
                </b-row> </b-col>
        </b-row>
        <hr class="my-1">
    </div>
</template>
<script>
export default {
    props: {
        'must-icon': {
            default: true,
            required: false,
        },
        icon: {
            default: 'PlusIcon',
            required: false,
        },
        tooltip: {
            default: '',
            required: false,
        },
        name: {
            default: '',
            required: false,
        },
        date: {
            default: null,
            required: false,
        }
    },
}
</script>