<template>
    <div class="w-100 select-dynamic">
        <b-input-group class="cursor-pointer"  :class="state === null  ? null : 'is-invalid'" >
            <b-form-input readonly v-model="text_field" :placeholder="placeholder" class="my-form-input cursor-pointer"
               @click="collapse = !collapse; toggle_icon = !toggle_icon"
                :state="state" />
            <b-input-group-append is-text @click="collapse = !collapse; toggle_icon = !toggle_icon">
                <feather-icon :icon="toggle_icon ? 'ChevronDownIcon' : 'ChevronRightIcon'" size="16" />
            </b-input-group-append>
        </b-input-group>
        <b-collapse v-model="collapse" class="border rounded p-1 custom-collapse w-100">
            <div class="w-100">
                <b-form-input v-model="search" placeholder="Buscar en la lista"
                    class="border-0 border-bottom"></b-form-input>
                <hr>
                <b-form-select v-model="selected" :options="search_text" :select-size="3"
                    @change="update_value"></b-form-select>
                <hr v-if="created">
                <b-link v-if="created" @click="create()"><feather-icon icon="PlusIcon" size="16" />
                    <span class="mr-25 align-middle">Crear</span></b-link>
            </div>
        </b-collapse>
    </div>
</template>
  
<script>
export default {
    props: {
        placeholder: String,
        options: Array,
        'on-value': String,
        'on-text': String,
        value: {
            default: '',
            required: false,
        },
        created: {
            default: true,
            required: false,
        },
        state: {
            default: null,
            required: false,
        },
    },
    data() {
        return {
            selected: '',
            toggle_icon: false,
            collapse: false,
            search: '',
            text_field: '',
        };
    },

    computed: {
        format_array() {
            return this.options.map(option => ({
                value: option[this.onValue || 'value'],
                text: option[this.onText || 'text'],
            }));
        },

        search_text() {
            if (!this.format_array || !this.format_array.length) {
                return []
            }
            return this.format_array.filter(item => {
                let props = Object.values(item)
                return props.some(prop => !this.search || ((typeof prop === 'string') ? prop.toLowerCase().includes(this.search.toLowerCase()) : (prop === null ? '' : prop.toString()).toLowerCase().includes(this.search.toLowerCase())))
            })
        },
    },

    watch: {
        value(new_value, old_value) {
            this.selected = new_value
        },
        selected(new_value, old_value) {
            this.update_text();
        },
        options(new_value, old_value) {
            this.update_text();
        },
    },

    methods: {
        create(event) {
            this.$emit("create");
            this.collapse = false;
        },

        update_value(event) {
            this.$emit("input", event);
            this.search = '';
            this.collapse = false;
        },

        update_text() {
            const index = this.format_array.findIndex(
                (p) => p.value == this.selected
            )
            if (index >= 0) {
                this.text_field = this.format_array[index].text
            } else {
                this.text_field = '';

            }
        }
    },

    mounted() {
        this.selected = this.value;
    }
};
</script>
<style scoped>
.my-form-input[readonly] {
    background-color: #fff;
}

.select-dynamic {
    position: relative;
}

.custom-collapse {
    background-color: #fff;
    z-index: 999;
    position: absolute;
}
</style>
