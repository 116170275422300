import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    scrollBehavior() {
        return { x: 0, y: 0 };
    },
    routes: [
        {
            path: "/",
            name: "inicio",
            component: () => import("@/views/Home.vue"),
            meta: {
                auth: true,
                pageTitle: "Inicio",
                breadcrumb: [
                    {
                        text: "Inicio",
                        headerTitle: "Indicadores generales",
                        headerSubTitle:
                            "Resumen de indicadores mensuales  de los viveros",
                        active: true,
                    },
                ],
            },
        },
        // EMPAQUES

        {
            path: "/gestion-empaques",
            component: {
                render: (c) => c("router-view"),
            },
            children: [
                {
                    path: "",
                    name: "gestion-empaques",
                    component: () => import("@/views/packagings/Main.vue"),
                    meta: {
                        auth: true,
                        pageTitle: "Empaques",
                        breadcrumb: [
                            {
                                text: "Empaques",
                                headerTitle: "Empaques",
                                headerSubTitle:
                                    "Reserve el material vegetal para el predio que esta arborizando",
                                active: true,
                            },
                        ],
                    },
                },
                {
                    path: "create",
                    name: "gestion-empaques",
                    component: () => import("@/views/packagings/Create.vue"),
                    meta: {
                        auth: true,
                        pageTitle: "Crear empaque",
                        breadcrumb: [
                            {
                                text: "Crear empaque",
                                headerTitle: "Crear empaque",
                                headerSubTitle: "Creacion de empaques",
                                active: true,
                            },
                        ],
                    },
                },

                {
                    path: "update/:id",
                    name: "gestion-empaques",
                    component: () => import("@/views/packagings/Update.vue"),
                    meta: {
                        auth: true,
                        pageTitle: "Editar empaque",
                        breadcrumb: [
                            {
                                text: "Editar empaque",
                                headerTitle: "Editar empaque",
                                headerSubTitle: "",
                                active: true,
                            },
                        ],
                    },
                },

                {
                    path: "show/:id",
                    name: "gestion-empaques",
                    component: () => import("@/views/packagings/Show.vue"),
                    meta: {
                        auth: true,
                        pageTitle: "Ver empaque",
                        breadcrumb: [
                            {
                                text: "Ver empaque",
                                headerTitle: "",
                                headerSubTitle: "Visualizacion de empaque",
                                active: true,
                            },
                        ],
                    },
                },
            ],
        },

        //INTEGRACIONES
        //CUENCAS
        {
            path: "/cuencas",
            name: "cuencas",
            component: () => import("@/views/integration/Main.vue"),
            meta: {
                auth: true,
                pageTitle: "Cuencas",
                breadcrumb: [
                    {
                        text: "Cuencas",
                        headerTitle: "Cuencas",
                        api: "basin",
                        headerSubTitle: "Visualizar integraciones de cuencas",
                        active: true,
                    },
                ],
            },
        },

        //CONTRATOS
        {
            path: "/contratos",
            name: "contratos",
            component: () => import("@/views/integration/Main.vue"),
            meta: {
                auth: true,
                pageTitle: "Contratos y Convenios",
                breadcrumb: [
                    {
                        text: "Contratos y Convenios",
                        headerTitle: "Contratos y Convenios",
                        api: "contract",
                        headerSubTitle:
                            "Visualizar integraciones de contratos y convenios",
                        active: true,
                    },
                ],
            },
        },

        //DIVIPOLA
        {
            path: "/info-divipola",
            name: "info-divipola",
            component: () => import("@/views/integration/Main.vue"),
            meta: {
                auth: true,
                pageTitle: "Informacion Divipola",
                breadcrumb: [
                    {
                        text: "Informacion Divipola",
                        headerTitle: "Informacion Divipola",
                        api: "dividepole",
                        headerSubTitle:
                            "Visualizar integraciones de informacion de divipola",
                        active: true,
                    },
                ],
            },
        },

        //REGIONALES
        {
            path: "/regionales",
            name: "regionales",
            component: () => import("@/views/integration/Main.vue"),
            meta: {
                auth: true,
                pageTitle: "DARS",
                breadcrumb: [
                    {
                        api: "regional",
                        headerSubTitle:
                            "Visualizar integraciones de regionales",
                        active: true,
                    },
                ],
            },
        },

        //TERCEROS
        {
            path: "/terceros",
            name: "terceros",
            component: () => import("@/views/integration/Main.vue"),
            meta: {
                auth: true,
                pageTitle: "Terceros",
                breadcrumb: [
                    {
                        text: "Terceros",
                        headerTitle: "Terceros",
                        api: "third",
                        headerSubTitle: "Visualizar integraciones de terceros",
                        active: true,
                    },
                ],
            },
        },

        //PRODUCTOS
        {
            path: "/gestion-productos",
            component: {
                render: (c) => c("router-view"),
            },
            children: [
                {
                    path: "",
                    name: "gestion-productos",
                    component: () => import("@/views/products/Main.vue"),
                    meta: {
                        auth: true,
                        pageTitle: "Productos",
                        breadcrumb: [
                            {
                                text: "Productos",
                                headerTitle: "Productos",
                                headerSubTitle:
                                    "Aqui podra consultar  la existencia del vivero",
                                active: true,
                            },
                        ],
                    },
                },
                {
                    path: "create",
                    name: "gestion-productos",
                    component: () => import("@/views/products/Create.vue"),
                    meta: {
                        auth: true,
                        pageTitle: "Crear producto",
                        breadcrumb: [
                            {
                                text: "Crear producto",
                                headerTitle: "Crear producto",
                                headerSubTitle: "Creacion de productos",
                                active: true,
                            },
                        ],
                    },
                },

                {
                    path: "show/:id",
                    name: "gestion-productos",
                    component: () => import("@/views/products/Show.vue"),
                    meta: {
                        auth: true,
                        pageTitle: "Ver producto",
                        breadcrumb: [
                            {
                                text: "Ver producto",
                                headerTitle: "",
                                headerSubTitle: "",
                                active: true,
                            },
                        ],
                    },
                },
                {
                    path: "adjustment/:type/:id",
                    name: "gestion-productos",
                    component: () => import("@/views/products/Adjustment.vue"),
                    beforeEnter: (to, from, next) => {
                        const types = [
                            "addition",
                            "elimination",
                            "available",
                            "area",
                            "seedling",
                            "loss",
                        ];
                        if (types.includes(to.params.type)) {
                            next();
                        } else {
                            next({ path: `/gestion-productos/show/${to.params.id}` });
                        }
                    },
                    meta: {
                        auth: true,
                        pageTitle: "Ajustar inventario",
                        breadcrumb: [
                            {
                                text: "Ajustar inventario",
                                headerTitle: "Ajustar inventario",
                                headerSubTitle: "",
                                active: true,
                            },
                        ],
                    },
                },
            ],
        },

        //ESPECIES
        {
            path: "/gestion-especies",
            component: {
                render: (c) => c("router-view"),
            },
            children: [
                {
                    path: "",
                    name: "gestion-especies",
                    component: () => import("@/views/species/Main.vue"),
                    meta: {
                        auth: true,
                        pageTitle: "Especies",
                        breadcrumb: [
                            {
                                text: "Especies",
                                headerTitle: "Especies",
                                headerSubTitle:
                                    "Reserve el material vegetal para el predio que esta arborizando",
                                active: true,
                            },
                        ],
                    },
                },
                {
                    path: "create",
                    name: "gestion-especies",
                    component: () => import("@/views/species/Create.vue"),
                    meta: {
                        auth: true,
                        pageTitle: "Crear especie",
                        breadcrumb: [
                            {
                                text: "Crear especie",
                                headerTitle: "Crear especie",
                                headerSubTitle: "Creacion de especie",
                                active: true,
                            },
                        ],
                    },
                },

                {
                    path: "update/:id",
                    name: "gestion-especies",
                    component: () => import("@/views/species/Update.vue"),
                    meta: {
                        auth: true,
                        pageTitle: "Editar especie",
                        breadcrumb: [
                            {
                                text: "Editar especie",
                                headerTitle: "Editar especie",
                                headerSubTitle: "",
                                active: true,
                            },
                        ],
                    },
                },

                {
                    path: "show/:id",
                    name: "gestion-especies",
                    component: () => import("@/views/species/Show.vue"),
                    meta: {
                        auth: true,
                        pageTitle: "Ver especie",
                        breadcrumb: [
                            {
                                text: "Ver especie",
                                headerTitle: "",
                                headerSubTitle:
                                    "Reserve el material vegetal para el predio que esta arborizando",
                                active: true,
                            },
                        ],
                    },
                },
            ],
        },

        //VIVEROS
        {
            path: "/gestion-viveros",
            component: {
                render: (c) => c("router-view"),
            },
            children: [
                {
                    path: "",
                    name: "gestion-viveros",
                    component: () => import("@/views/nurseries/Main.vue"),
                    meta: {
                        auth: true,
                        pageTitle: "Viveros",
                        breadcrumb: [
                            {
                                text: "Viveros",
                                headerTitle: "Viveros",
                                headerSubTitle:
                                    "Aqui podra visualizar los viveros",
                                active: true,
                            },
                        ],
                    },
                },
                {
                    path: "create",
                    name: "gestion-viveros",
                    component: () => import("@/views/nurseries/Create.vue"),
                    meta: {
                        auth: true,
                        pageTitle: "Crear vivero",
                        breadcrumb: [
                            {
                                text: "Crear vivero",
                                headerTitle: "Crear vivero",
                                headerSubTitle: "Creacion de vivero",
                                active: true,
                            },
                        ],
                    },
                },

                {
                    path: "update/:id",
                    name: "gestion-viveros",
                    component: () => import("@/views/nurseries/Update.vue"),
                    meta: {
                        auth: true,
                        pageTitle: "Editar vivero",
                        breadcrumb: [
                            {
                                text: "Editar vivero",
                                headerTitle: "Editar vivero",
                                headerSubTitle: "",
                                active: true,
                            },
                        ],
                    },
                },

                {
                    path: "show/:id",
                    name: "gestion-viveros",
                    component: () => import("@/views/nurseries/Show.vue"),
                    meta: {
                        auth: true,
                        pageTitle: "Ver vivero",
                        breadcrumb: [
                            {
                                text: "Ver vivero",
                                headerTitle: "",
                                headerSubTitle:
                                    "Aqui podra visualizar el vivero a detalle",
                                active: true,
                            },
                        ],
                    },
                },
            ],
        },

        //PROFILES
        {
            path: "/profiles",
            component: {
                render: (c) => c("router-view"),
            },
            children: [
                {
                    path: "",
                    name: "profiles",
                    component: () => import("@/views/profiles/Main.vue"),
                    meta: {
                        auth: true,
                        pageTitle: "Roles",
                        breadcrumb: [
                            {
                                text: "Roles",
                                headerTitle: "Roles",
                                headerSubTitle:
                                    "Administracion de roles para el usuario",
                                active: true,
                            },
                        ],
                    },
                },

                {
                    path: "create",
                    name: "profiles",
                    component: () => import("@/views/profiles/Create.vue"),
                    meta: {
                        auth: true,
                        pageTitle: "Crear rol",
                        breadcrumb: [
                            {
                                text: "Crear rol",
                                headerTitle: "Crear rol",
                                headerSubTitle:
                                    "Creacion de roles y asignacion de permisos",
                                active: true,
                            },
                        ],
                    },
                },

                {
                    path: "update/:id",
                    name: "profiles",
                    component: () => import("@/views/profiles/Update.vue"),
                    meta: {
                        auth: true,
                        pageTitle: "Editar rol",
                        breadcrumb: [
                            {
                                text: "Editar rol",
                                headerTitle: "",
                                headerSubTitle: "Editar permisos por rol",
                                active: true,
                            },
                        ],
                    },
                },
            ],
        },

        //USUARIOS

        {
            path: "/usuarios",
            component: {
                render: (c) => c("router-view"),
            },
            children: [
                {
                    path: "",
                    name: "usuarios",
                    component: () => import("@/views/users/Main.vue"),
                    meta: {
                        auth: true,
                        pageTitle: "Usuarios",
                        breadcrumb: [
                            {
                                text: "Usuarios",
                                headerTitle: "Usuarios",
                                headerSubTitle:
                                    "Administracion de usuarios",
                                active: true,
                            },
                        ],
                    },
                },

                {
                    path: "create",
                    name: "usuarios",
                    component: () => import("@/views/users/Create.vue"),
                    meta: {
                        auth: true,
                        pageTitle: "Crear usuario",
                        breadcrumb: [
                            {
                                text: "Crear usuario",
                                headerTitle: "Crear usuario",
                                headerSubTitle:
                                    "Creacion de usuarios",
                                active: true,
                            },
                        ],
                    },
                },
                {
                    path: "show/:id",
                    name: "usuarios",
                    component: () => import("@/views/users/Show.vue"),
                    meta: {
                        auth: true,
                        pageTitle: "Ver vivero",
                        breadcrumb: [
                            {
                                text: "Ver vivero",
                                headerTitle: "",
                                headerSubTitle:
                                    "Aqui podra visualizar el usuario a detalle",
                                active: true,
                            },
                        ],
                    },
                },

                {
                    path: "update/:id",
                    name: "usuarios",
                    component: () => import("@/views/users/Update.vue"),
                    meta: {
                        auth: true,
                        pageTitle: "Editar usuario",
                        breadcrumb: [
                            {
                                text: "Editar usuario",
                                headerTitle: "",
                                headerSubTitle: "Editar usuario",
                                active: true,
                            },
                        ],
                    },
                },
            ],
        },
        //PREDIOS
        {
            path: "/gestion-predios",
            component: {
                render: (c) => c("router-view"),
            },
            children: [
                {
                    path: "",
                    name: "gestion-predios",
                    component: () => import("@/views/lands/Main.vue"),
                    meta: {
                        auth: true,
                        pageTitle: "Predios",
                        breadcrumb: [
                            {
                                text: "Predios",
                                headerTitle: "Predios",
                                headerSubTitle:
                                    "Aqui podra visualizar los predios",
                                active: true,
                            },
                        ],
                    },
                },
                {
                    path: "create",
                    name: "gestion-predios",
                    component: () => import("@/views/lands/Create.vue"),
                    meta: {
                        auth: true,
                        pageTitle: "Crear predio",
                        breadcrumb: [
                            {
                                text: "Crear predio",
                                headerTitle: "Crear predio",
                                headerSubTitle: "Creacion de predio",
                                active: true,
                            },
                        ],
                    },
                },

                {
                    path: "update/:id",
                    name: "gestion-predios",
                    component: () => import("@/views/lands/Update.vue"),
                    meta: {
                        auth: true,
                        pageTitle: "Editar predio",
                        breadcrumb: [
                            {
                                text: "Editar predio",
                                headerTitle: "Editar predio",
                                headerSubTitle: "",
                                active: true,
                            },
                        ],
                    },
                },

                {
                    path: "show/:id",
                    name: "gestion-predios",
                    component: () => import("@/views/lands/Show.vue"),
                    meta: {
                        auth: true,
                        pageTitle: "Ver predio",
                        breadcrumb: [
                            {
                                text: "Ver predio",
                                headerTitle: "",
                                headerSubTitle:
                                    "Aqui podra visualizar el predio a detalle",
                                active: true,
                            },
                        ],
                    },
                },
            ],
        },

        {
            path: "/login",
            name: "login",
            component: () => import("@/views/Login.vue"),
            meta: {
                layout: "full",
            },
        },
        {
            path: "/error-404",
            name: "error-404",
            component: () => import("@/views/error/Error404.vue"),
            meta: {
                layout: "full",
            },
        },
        {
            path: "*",
            redirect: "error-404",
        },
    ],
});

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
    // Remove initial loading
    const appLoading = document.getElementById("loading-bg");
    if (appLoading) {
        appLoading.style.display = "none";
    }
});

export default router;
