import Vue from "vue";
import { ToastPlugin, ModalPlugin } from "bootstrap-vue";
import VueCompositionAPI from "@vue/composition-api";
import moment from "moment";
import "moment-timezone";
import "moment/locale/es";

import router from "./router";
import store from "./store";
import App from "./App.vue";

// Global Components
import "./global-components";

// 3rd party plugins
import "@/libs/portal-vue";
import "@/libs/toastification";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

// BSV Plugin Registration
Vue.use(ToastPlugin);
Vue.use(ModalPlugin);

// Composition API
Vue.use(VueCompositionAPI);

// import core styles
require("@core/scss/core.scss");

// import assets styles
require("@/assets/scss/style.scss");
import axios from "./axios";

Vue.config.productionTip = false;
Vue.prototype.$http = axios;
router.beforeEach((to, from, next) => {
    if(!to.meta.layout) document.body.style.zoom = "100%";
    else  document.body.style.zoom = "100%";
    let storage = JSON.parse(localStorage.getItem("current_user"));
    const minutes_day = () => {
        let hour = new Date() - new Date(storage.user.date);
        hour = Math.round(hour / 1000 / 60);
        if (storage.user.remember_me) {
            return true;
        } else if (!storage.user.remember_me) {
            return hour <= 1440;
        }
    };
    if (to.meta.auth) {
        if (storage) {
            if (minutes_day()) {
                axios.defaults.headers.common.Authorization =
                    "Bearer " + storage.token;
                next();
            } else {
                localStorage.removeItem("current_user");
                axios.get("/api/logout", {
                    headers: { Authorization: `Bearer ${storage.token}` },
                });
                axios.defaults.headers.common.Authorization = "";
                next("/login");
            }
        } else {
            axios.defaults.headers.common.Authorization = "";
            next("/login");
        }
    } else if (to.name === "login") {
        if (storage && minutes_day()) {
            next("/");
        } else {
            next();
        }
    } else {
        next();
    }
});

Vue.mixin({
    data() {
        return {
            loader_component: "",
            permissions: {
                s: 0,
                c: 0,
                u: 0,
                d: 0,
            },
        };
    },
    created() {
        axios.interceptors.response.use(
            (response) => response,
            (error) => {
                if (error.response.status === 401) {
                    axios.defaults.headers.common.Authorization = "";
                    localStorage.removeItem("current_user");
                    router.push("/login").catch((failure) => {});
                }

                return Promise.reject(error);
            }
        );
    },

    computed: {
        getHeaderSubTitle() {
            return this.$store.state.header.subtitle;
        },

        getHeaderTitle() {
            return this.$store.state.header.title;
        },
    },

    methods: {
        notify(title, text, type) {
            this.$toast({
                component: ToastificationContent,
                props: {
                    text: text,
                    title: title,
                    icon:
                        type == "danger"
                            ? "ErrorIcon"
                            : type == "primary"
                            ? "CheckCircleIcon"
                            : type == "warning"
                            ? "AlertTriangleIcon"
                            : "",
                    variant: type,
                },
            });
        },

        formatNumber(value) {
            return value.toLocaleString("es", {useGrouping: true});
        },

        formatCurrency(value) {
            return '$ '+value.toLocaleString("es", { useGrouping: true, style: 'currency', currency: 'COP'});
        },

        async getPermission(valide_permission = null) {
            try {
                const data = (
                    await this.$http.get(
                        "configuration/module/show-module-permissions",
                        {
                            params: {
                                name: this.$route.name,
                            },
                        }
                    )
                ).data.data;
                if (data.s !== 1) {
                    this.$router.push({ name: "error-404" });
                }
                if (valide_permission) {
                    if (data[valide_permission] !== 1) {
                        this.$router.push({ name: "error-404" });
                    }
                }
                this.permissions = data;
                return data;
            } catch (error) {
                this.$router.push({ name: "error-404" });
            }
        },

        setHeaderSubTitle(text) {
            this.$store.commit("updateHeaderSubTitle", text);
        },

        setHeaderTitle(text) {
            this.$store.commit("updateHeaderTitle", text);
        },

        loading() {
            this.loader_component = this.$loading.show();
        },

        not_loading() {
            this.loader_component.hide();
        },
    },
});

moment.tz.setDefault("America/Bogota");
moment.locale("es");
Vue.prototype.$moment = moment;

new Vue({
    router,
    store,
    render: (h) => h(App),
}).$mount("#app");
