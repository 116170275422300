import Vue from "vue";
import FeatherIcon from "@core/components/feather-icon/FeatherIcon.vue";
import {
    ValidationProvider,
    ValidationObserver,
    extend,
    localize,
} from "vee-validate";
import * as rules from "vee-validate/dist/rules";
import es from "vee-validate/dist/locale/es.json";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import BSelectCreated from "@/components/BSelectCreated.vue";
import ButtonTop from "@/components/ButtonTop.vue";

import * as b from "bootstrap-vue";
import ActaIcon from "@/assets/icons/ActaIcon.vue";
import AdmiIcon from "@/assets/icons/AdmiIcon.vue";
import DashIcon from "@/assets/icons/DashIcon.vue";
import DespIcon from "@/assets/icons/DespIcon.vue";
import InveIcon from "@/assets/icons/InveIcon.vue";
import OrdeIcon from "@/assets/icons/OrdeIcon.vue";
import ProdIcon from "@/assets/icons/ProdIcon.vue";
import RepoIcon from "@/assets/icons/RepoIcon.vue";
import SoliIcon from "@/assets/icons/SoliIcon.vue";

Object.keys(rules).forEach(rule => {
    extend(rule, rules[rule]);
})

localize({
    es,
});

localize("es");

Vue.component(FeatherIcon.name, FeatherIcon);
Vue.use(Loading);
Vue.component("validation-provider", ValidationProvider);
Vue.component("validation-observer", ValidationObserver);
Vue.component("b-button", b.BButton);
Vue.component("b-form", b.BForm);
Vue.component("b-input-group", b.BInputGroup);
Vue.component("b-input-group-append", b.BInputGroupAppend);
Vue.component("b-form-input", b.BFormInput);
Vue.component("b-form-group", b.BFormGroup);
Vue.component("b-form-datepicker", b.BFormDatepicker);
Vue.component("b-col", b.BCol);
Vue.component("b-row", b.BRow);
Vue.component("b-card", b.BCard);
Vue.component("b-link", b.BLink);
Vue.component("b-card-text", b.BCardText);
Vue.component("b-card-title", b.BCardTitle);
Vue.component("b-card-header", b.BCardHeader);
Vue.component("b-card-body", b.BCardBody);
Vue.component("b-dropdown", b.BDropdown);
Vue.component("b-dropdown-item", b.BDropdownItem);
Vue.component("b-table", b.BTable);
Vue.component("b-tr", b.BTr);
Vue.component("b-td", b.BTd);
Vue.component("b-th", b.BTh);
Vue.component("b-pagination", b.BPagination);
Vue.component("b-pagination", b.BPagination);
Vue.component("b-form-select", b.BFormSelect);
Vue.directive("b-tooltip", b.VBTooltip);
Vue.directive("b-toggle", b.VBToggle);
Vue.component("b-form-textarea", b.BFormTextarea);
Vue.directive("b-popover", b.VBPopover);
Vue.component("b-form-checkbox", b.BFormCheckbox);
Vue.component("b-tabs", b.BTabs);
Vue.component("b-tab", b.BTab);
Vue.component("b-overlay", b.BOverlay);
Vue.component("b-media", b.BMedia);
Vue.component("b-badge", b.BBadge);
Vue.component("b-img", b.BImg);
Vue.component("b-spinner", b.BSpinner);
Vue.component("b-alert", b.BAlert);
Vue.component("b-overlay", b.BOverlay);
Vue.component("b-collapse", b.BCollapse);
Vue.component("b-form-radio", b.BFormRadio);
Vue.component("b-form-radio-group", b.BFormRadioGroup);
Vue.component("b-select-created", BSelectCreated);
Vue.component("button-top", ButtonTop);
Vue.component("acta-icon", ActaIcon);
Vue.component("admi-icon", AdmiIcon);
Vue.component("dash-icon", DashIcon);
Vue.component("desp-icon", DespIcon);
Vue.component("inve-icon", InveIcon);
Vue.component("orde-icon", OrdeIcon);
Vue.component("prod-icon", ProdIcon);
Vue.component("repo-icon", RepoIcon);
Vue.component("soli-icon", SoliIcon);
